import React, {
  createRef,
  FC,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';
import { Button, ButtonTheme } from 'components/Button';
import { ErrorTitles } from 'components/ErrorMessage';
import { ErrorTheme, GeneralError } from 'components/GeneralError';
import { convertItems, ConvertType } from 'components/TreeSelect/TreeSelect';

import { IconType } from '../Icon';

import styles from './Tree.module.scss';

export interface TreeItem {
  label?: string | number;
  subLabel: string | number;
  id: string | number;
  hasChildren: boolean;
  children: TreeItem[];
  className?: string;
  isSelectable?: boolean;
  isMultiSelect?: boolean;
  expanded?: boolean;
  actionLabel?: string;
  parentId?: string | number | null;
  isHidden?: boolean;
}

interface Props {
  className?: string;
  actionLabelClassName?: string;
  noAnimation?: boolean;
  data: TreeItem[];
  isPlainList?: boolean;
  onClick: (item: TreeItem) => void;
  onItemStateChange?: (item: TreeItem, expanded: boolean) => void;
  highlightedInputMatchId?: string | number;
  highlightedItemId?: string | number;
  onActionLabelClick?: (item: TreeItem) => void;
  childIdsLoading?: Array<string | number>;
  iconType?: IconType.CaretRight | IconType.ChevronRight;
  rowIndentation?: number;
  expandBtnTabIndex?: number;
  actionLabelTabIndex?: number;
  triggeredKeyboardEvent?: React.KeyboardEvent<HTMLElement>;
}

interface visibleTreeOptionsSet {
  item: TreeItem;
  groupStartIndex: number;
  isVisible: boolean;
  hiddenIndex: number | null;
}

const transitionClassNames = {
  appear: styles.opened,
  enterActive: styles.opened,
  enterDone: styles.opened
};

const deepReducer = <T extends {}>(acc: T, item: TreeItem): T => {
  if (item.children?.length) {
    return {
      ...acc,
      [item.id]: item.expanded || false,
      ...item.children.reduce(deepReducer, {})
    };
  }
  return { ...acc, [item.id]: item.expanded || false };
};

export const Tree: FC<Props> = ({
  data,
  onClick,
  onItemStateChange,
  onActionLabelClick,
  isPlainList,
  className,
  actionLabelClassName,
  highlightedItemId,
  highlightedInputMatchId,
  childIdsLoading,
  iconType = IconType.CaretRight,
  rowIndentation = 32,
  expandBtnTabIndex = -1,
  actionLabelTabIndex = -1,
  triggeredKeyboardEvent
}) => {
  const itemExpandedByClick = useRef<TreeItem | null>(null);
  const highlightedId = highlightedItemId || highlightedInputMatchId;

  const [dataForKeysNavigation, setDataForKeysNavigation] = useState<
    visibleTreeOptionsSet[]
  >(
    data.map((item) => ({
      item: item,
      groupStartIndex: 0,
      isVisible: true,
      hiddenIndex: null
    }))
  );

  const [currentOptionIndex, setCurrentOptionIndex] = useState(0);

  const [treeState, setTreeState] = useState(
    data.reduce<Record<string | number, boolean>>(deepReducer, {})
  );

  const [hasScrolled, setHasScrolled] = useState<boolean>(false);

  const ref = createRef<HTMLDivElement>();
  const firstRender = useRef<boolean>(true);

  useEffect(() => {
    setTreeState(
      data.reduce<Record<string | number, boolean>>(deepReducer, {})
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlainList]);

  useEffect(() => {
    setHasScrolled(false);
  }, [highlightedItemId]);

  useEffect(() => {
    if (triggeredKeyboardEvent) {
      handleKeyDown(triggeredKeyboardEvent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggeredKeyboardEvent]);

  useEffect(() => {
    if (firstRender.current) return;
    const selectedOption =
      itemExpandedByClick.current !== null
        ? itemExpandedByClick.current
        : dataForKeysNavigation[currentOptionIndex].item;
    if (itemExpandedByClick.current !== null) {
      itemExpandedByClick.current = null;
    }
    const selectedOptionWithChildrenData = convertItems(
      data,
      ConvertType.flatten
    ).find((treeItem) => treeItem.id === selectedOption.id);
    if (
      selectedOptionWithChildrenData &&
      !childIdsLoading?.length &&
      dataForKeysNavigation.findIndex(
        (x) => x.item.id === selectedOptionWithChildrenData.children[0]?.id
      ) === -1
    ) {
      const newDataForKeysNavigation = [...dataForKeysNavigation];
      newDataForKeysNavigation[currentOptionIndex].item =
        selectedOptionWithChildrenData;
      setDataForKeysNavigation(newDataForKeysNavigation);
      onRightArrowPress(selectedOptionWithChildrenData.children);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, childIdsLoading]);

  useEffect(() => {
    if (
      dataForKeysNavigation[currentOptionIndex]?.item?.id &&
      triggeredKeyboardEvent
    ) {
      scrollToHighlightedElement(
        dataForKeysNavigation[currentOptionIndex]?.item?.id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOptionIndex, dataForKeysNavigation]);

  useEffect(() => {
    //keep this useEffect after all useEffects
    firstRender.current = false;
  }, []);

  const toggleTreeItem = (id: string | number, expanded: boolean) => {
    setTreeState({
      ...treeState,
      [id]: expanded
    });
  };

  const scrollToHighlightedElement = useCallback(
    (itemId: string | number | undefined) => {
      const target = document?.querySelector<HTMLDivElement>(
        `[data-id='${itemId}']`
      );
      const container = ref.current;

      if (target && container) {
        const targetOffset = target.offsetTop + target.clientHeight / 2;
        const containerHeight = container.parentElement!.clientHeight;
        try {
          let scrollHeight = 0;
          if (targetOffset > containerHeight / 2) {
            scrollHeight = targetOffset - containerHeight / 2;
          }
          container.parentElement!.scrollTop = scrollHeight;
          setHasScrolled(true);
        } catch (e) {}
      }
    },
    [ref]
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    const currentHighlightedItem =
      dataForKeysNavigation[currentOptionIndex].item;
    switch (event.key) {
      case 'ArrowUp':
        if (currentOptionIndex === 0) {
          setCurrentOptionIndex(dataForKeysNavigation.length - 1);
        } else {
          setCurrentOptionIndex(findNextSelectable(true));
        }
        break;
      case 'ArrowDown':
        if (currentOptionIndex === dataForKeysNavigation.length - 1) {
          setCurrentOptionIndex(0);
        } else {
          setCurrentOptionIndex(findNextSelectable(false));
        }
        break;
      case 'ArrowRight':
        if (!treeState[currentHighlightedItem.id]) {
          const SeletedChildren = currentHighlightedItem.children;
          if (SeletedChildren.length > 0) {
            if (
              dataForKeysNavigation.findIndex(
                (x) => x.item.id === SeletedChildren[0]?.id
              ) !== -1
            ) {
              setIsVisible(true);
              toggleTreeItem(
                dataForKeysNavigation[currentOptionIndex].item.id,
                true
              );
              setCurrentOptionIndex(currentOptionIndex + 1);
            } else {
              onRightArrowPress(currentHighlightedItem.children);
            }
          } else if (
            currentHighlightedItem.hasChildren &&
            currentHighlightedItem.children.length === 0
          ) {
            onItemStateChange &&
              onItemStateChange(currentHighlightedItem, true);
          }
        } else if (treeState[currentHighlightedItem.id]) {
          setCurrentOptionIndex(currentOptionIndex + 1);
        }
        break;
      case 'ArrowLeft':
        if (treeState[currentHighlightedItem.id]) {
          setIsVisible(false);
          toggleTreeItem(currentHighlightedItem.id, false);
        } else if (
          dataForKeysNavigation[currentOptionIndex].groupStartIndex !== 0
        ) {
          setCurrentOptionIndex(
            dataForKeysNavigation[currentOptionIndex].groupStartIndex - 1
          );
        }
        break;
      case 'Enter':
        currentHighlightedItem.isSelectable && onClick(currentHighlightedItem);
        break;
      default:
        break;
    }
  };

  const onRightArrowPress = (selectedOptionWithChildrenData: TreeItem[]) => {
    if (!selectedOptionWithChildrenData.length) return;
    toggleTreeItem(dataForKeysNavigation[currentOptionIndex].item.id, true);
    setDataForKeysNavigation([
      ...dataForKeysNavigation.slice(0, currentOptionIndex + 1),
      ...selectedOptionWithChildrenData.map((item: TreeItem) => ({
        item: item,
        groupStartIndex: currentOptionIndex + 1,
        isVisible: true,
        hiddenIndex: null
      })),
      ...dataForKeysNavigation.slice(
        currentOptionIndex + 1,
        dataForKeysNavigation.length
      )
    ]);
    setCurrentOptionIndex(currentOptionIndex + 1);
  };

  const findNextSelectable = (reverse: boolean) => {
    if (reverse) {
      for (let x = currentOptionIndex - 1; x >= 0; x--) {
        if (dataForKeysNavigation[x].isVisible) return x;
      }
    } else {
      for (
        let x = currentOptionIndex + 1;
        x < dataForKeysNavigation.length;
        x++
      ) {
        if (dataForKeysNavigation[x].isVisible) {
          return x;
        }
      }
    }
    return currentOptionIndex;
  };

  const setIsVisible = (value: boolean) => {
    const notHiddenitems = {};
    for (let x = 0; x <= currentOptionIndex; x++) {
      if (!notHiddenitems[dataForKeysNavigation[x].groupStartIndex]) {
        notHiddenitems[dataForKeysNavigation[x].groupStartIndex] = true;
      }
    }
    const newDataForKeysNavigation = [...dataForKeysNavigation];
    for (
      let x = currentOptionIndex + 1;
      x < newDataForKeysNavigation.length;
      x++
    ) {
      if (notHiddenitems[dataForKeysNavigation[x].groupStartIndex]) {
        break;
      }
      if (!value && newDataForKeysNavigation[x].isVisible) {
        newDataForKeysNavigation[x].isVisible = false;
        newDataForKeysNavigation[x].hiddenIndex = currentOptionIndex;
      } else if (
        value &&
        newDataForKeysNavigation[x].hiddenIndex === currentOptionIndex
      ) {
        newDataForKeysNavigation[x].isVisible = value;
        newDataForKeysNavigation[x].hiddenIndex = null;
      }
    }
    setDataForKeysNavigation(newDataForKeysNavigation);
  };

  const renderTreeGroup = (
    isChildrenLoading: boolean,
    data: TreeItem[],
    expanded: boolean,
    treeDepth: number = 1
  ) => {
    const firstRowIndentation = 0;
    const isLowestDepth = treeDepth === 1;
    const marginSize = isLowestDepth
      ? firstRowIndentation
      : (treeDepth - 1) * rowIndentation;

    const toggleTree = (item: TreeItem, expanded: boolean) => {
      onItemStateChange && onItemStateChange(item, expanded);
      const toggledOptionIndex = dataForKeysNavigation.findIndex(
        (option) => option.item.id === item.id
      );
      if (toggledOptionIndex !== -1) {
        setCurrentOptionIndex(toggledOptionIndex);
        if (expanded) {
          itemExpandedByClick.current = item;
        }
      }
      toggleTreeItem(item.id, expanded);
    };

    const isErrorVisible = !data.length && !isChildrenLoading && expanded;

    return (
      <CSSTransition
        in={expanded}
        appear={expanded}
        timeout={700}
        mountOnEnter
        unmountOnExit
        classNames={transitionClassNames}
        onEntered={
          !hasScrolled
            ? () => scrollToHighlightedElement(highlightedItemId)
            : undefined
        }
      >
        <div
          className={cn(
            styles.tree,
            isLowestDepth && styles['no-animation'],
            isLowestDepth && expanded && className
          )}
        >
          <div ref={isLowestDepth ? ref : undefined} data-testid='tree'>
            {isErrorVisible ? (
              <GeneralError
                theme={ErrorTheme.Secondary}
                title={ErrorTitles.Load}
                style={{ paddingLeft: `${marginSize}px` }}
              />
            ) : (
              data.map((item) => {
                const {
                  id,
                  label,
                  subLabel,
                  children,
                  hasChildren,
                  className: labelClassName
                } = item;
                const expanded = treeState[id];

                const nextChildrenLoading = !!childIdsLoading?.find(
                  (id) => id === item.id
                );

                if (!isPlainList && item.isHidden) {
                  return (
                    <Fragment key={id}>
                      {renderTreeGroup(
                        nextChildrenLoading,
                        children,
                        true,
                        treeDepth
                      )}
                    </Fragment>
                  );
                } else if (isPlainList && item.isHidden) {
                  return null;
                }

                return (
                  <Fragment key={id}>
                    <div
                      data-testid={`row-${treeDepth}`}
                      data-id={id}
                      className={cn(styles['tree-row'], {
                        [styles.highlighted]:
                          (highlightedId && id === highlightedId) ||
                          (dataForKeysNavigation[currentOptionIndex] &&
                            id ===
                              dataForKeysNavigation[currentOptionIndex]?.item
                                ?.id),
                        [styles['not-selectable']]: !item.isSelectable
                      })}
                      onClick={() => {
                        item.isSelectable && onClick(item);
                      }}
                    >
                      <Button
                        data-testid={`expander-${treeDepth}`}
                        icon={iconType}
                        theme={ButtonTheme.SecondaryTransparent}
                        style={{ marginLeft: `${marginSize}px` }}
                        className={cn(styles['icon-button'], {
                          [styles.hidden]: !hasChildren,
                          [styles.empty]: isPlainList || !hasChildren
                        })}
                        iconClassName={cn(styles.icon, {
                          [styles.expanded]: expanded,
                          [styles.hidden]: !hasChildren
                        })}
                        onClick={(e) => {
                          e.stopPropagation();
                          hasChildren && toggleTree(item, !expanded);
                        }}
                        tabIndex={expandBtnTabIndex}
                      />
                      <div className={styles.labels}>
                        <div className={styles['labels-container']}>
                          {label && (
                            <span
                              className={cn(styles.label, {
                                [styles['not-selectable']]: !item.isSelectable
                              })}
                              data-testid={'label'}
                            >
                              {label}
                            </span>
                          )}
                          <span
                            className={cn(styles['sub-label'], labelClassName, {
                              [styles['not-selectable']]: !item.isSelectable
                            })}
                            data-testid={'sub-label'}
                          >
                            {subLabel}
                          </span>
                        </div>

                        {item.actionLabel && onActionLabelClick && isPlainList && (
                          <Button
                            data-testid={'action-label'}
                            className={cn(
                              styles['action-label'],
                              actionLabelClassName
                            )}
                            onClick={(e) => {
                              e.stopPropagation();
                              onActionLabelClick(item);
                            }}
                            title={item.actionLabel}
                            theme={ButtonTheme.SecondaryTransparent}
                            tabIndex={actionLabelTabIndex}
                          />
                        )}
                      </div>
                    </div>
                    {!isPlainList &&
                      renderTreeGroup(
                        nextChildrenLoading,
                        children,
                        expanded,
                        treeDepth + 1
                      )}
                  </Fragment>
                );
              })
            )}
          </div>
        </div>
      </CSSTransition>
    );
  };

  return renderTreeGroup(false, data, true);
};
