import React, { ReactElement, Ref, useMemo, useState } from 'react';
import { Props as SelectProps, ValueType } from 'react-select';
import { components, MenuListComponentProps } from 'react-select';
import { NoticeProps } from 'react-select/src/components/Menu';
import { Select, SelectOptionType, SelectRef } from 'components/Select';
import { Tree, TreeItem } from 'components/Tree';

import { FormInput } from '../FormInput';

interface Props<T extends SelectOptionType> extends SelectProps<T> {
  className?: string;
  treeClassName?: string;
  items: TreeItem[];
  readOnly?: boolean;
  readOnlyValue?: string;
  highlightedItemId?: number;
  isPlainList?: boolean;
  childIdsLoading?: Array<string | number>;
  onItemStateChange?: (item: TreeItem, expanded: boolean) => void;
  onActionLabelClick?: (item: TreeItem) => void;
  triggeredKeyboardEvent?: React.KeyboardEvent<HTMLElement>;
}

export enum ConvertType {
  deepest = 'deepest',
  flatten = 'flatten'
}

export const convertItems = (items: TreeItem[], convertType: ConvertType) => {
  const flatten = (acc: TreeItem[], item: TreeItem): TreeItem[] => {
    if (item.children?.length) {
      return [
        ...acc,
        ...(convertType === ConvertType.flatten ? [item] : []),
        ...item.children.reduce(flatten, [])
      ];
    }

    return [...acc, item];
  };

  return items.reduce(flatten, []);
};

export const findIdByMatch = (
  value: string = '',
  items: TreeItem[]
): string | number => {
  if (!value) {
    return '';
  }

  const matchedItem = items.find(({ label, subLabel }) => {
    const payload = [label, subLabel].filter(Boolean).join(' ').toLowerCase();

    return payload.includes(value.toLowerCase());
  });

  return matchedItem?.id || '';
};

const MenuList: <T extends SelectOptionType>(
  p: MenuListComponentProps<T>
) => React.ReactElement<MenuListComponentProps<T>> = (props) => {
  const { selectProps, setValue } = props;
  const {
    onItemStateChange,
    inputValue,
    treeOptions = [],
    treeClassName,
    highlightedItemId,
    onActionLabelClick,
    childIdsLoading,
    triggeredKeyboardEvent
  } = selectProps as MenuListComponentProps<{
    label: string;
    value: string;
  }>['selectProps'] &
    Props<SelectOptionType> & {
      treeOptions: TreeItem[];
    };

  const flattenTreeOptions = useMemo(() => {
    return convertItems(treeOptions, ConvertType.flatten);
  }, [treeOptions]);

  const highlightedInputMatchId = useMemo(() => {
    return findIdByMatch(inputValue, flattenTreeOptions);
  }, [inputValue, flattenTreeOptions]);

  if (!treeOptions.length) {
    return (
      <components.NoOptionsMessage
        {...(props as unknown as NoticeProps<any>)}
      />
    );
  }

  const isPlainList = !treeOptions.find((item) => item.hasChildren);

  return (
    <Tree
      triggeredKeyboardEvent={triggeredKeyboardEvent}
      childIdsLoading={childIdsLoading}
      isPlainList={isPlainList}
      onItemStateChange={onItemStateChange}
      onActionLabelClick={onActionLabelClick}
      data={treeOptions}
      highlightedItemId={highlightedItemId}
      highlightedInputMatchId={highlightedInputMatchId}
      className={treeClassName}
      onClick={({ id, label, subLabel, isMultiSelect }) => {
        setValue(
          {
            isMultiSelect,
            value: String(id),
            label: [label, subLabel].filter(Boolean).join(' ')
          } as ValueType<any>,
          'select-option'
        );
      }}
    />
  );
};

const TreeSelectInner: <T extends SelectOptionType>(
  p: Props<T>,
  ref?: SelectRef<T>
) => React.ReactElement<Props<T>> = (
  {
    className,
    items,
    onChange,
    onItemStateChange,
    triggeredKeyboardEvent,
    treeClassName,
    readOnly,
    readOnlyValue = '-',
    ...rest
  },
  ref
) => {
  const [triggeredEvent, setTriggeredEvent] =
    useState<React.KeyboardEvent<HTMLElement>>();
  if (readOnly) {
    return <FormInput multiline readOnly value={readOnlyValue} />;
  }

  const getKeyboardEvent = (event: React.KeyboardEvent<HTMLElement>) => {
    setTriggeredEvent({ ...event });
  };

  return (
    <div className={className}>
      <Select
        {...rest}
        ref={ref}
        components={{ MenuList }}
        treeOptions={items}
        triggeredKeyboardEvent={triggeredEvent}
        onKeyboardEvent={getKeyboardEvent}
        onItemStateChange={onItemStateChange}
        treeClassName={treeClassName}
        onChange={(value, action) => {
          onChange && onChange(value, action);
        }}
      />
    </div>
  );
};

export const TreeSelect = React.forwardRef(TreeSelectInner) as <
  T extends SelectOptionType
>(
  p: Props<T> & { ref?: Ref<HTMLSelectElement> }
) => ReactElement;
